import React from 'react';

import Head from '../../components/Head';
import TodoSubList from '../../components/TodoSubList';

import '../../style/_common.scss';

class TodoPage extends React.Component {
  render() {
    const title = 'HIPAA Compliance Checklist For DPC';
    const desc = `Follow these steps to stay HIPAA compliant in your direct primary care practice.`;
    return (
      <TodoSubList title={title} desc={desc} id="hipaa">
        <Head title={title} description={desc} url="/todo/hipaa/" image="/og_image.jpg" />
      </TodoSubList>
    );
  }
}

export default TodoPage;
